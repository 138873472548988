@import url('https://fonts.googleapis.com/css?family=Merriweather:100,200,300,300i,400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

body {
    font-family: "Open Sans", serif;
    color:white;
    /*background-color:#e09214;*/
    background-image:url("cssimages/background.jpeg");
}

.wrapper {
    /*background-color: #e09214;*/
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-container__title {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 1.3;
    font-family: 'Roboto Slab', serif;
}

.form-container {
    background-color: #0a2333;
    min-height: 600px;
    height: auto;
    padding-top: 5px;
    padding-left: 50px;
}

input[type="text"] {
    background-color: transparent;
    border: 0;
    border-bottom: solid 1px #e09214;
    width: 30%;
    padding-bottom: 4px;
    color: #fff !important;
    font-weight: lighter;
    letter-spacing: 2px;
    margin-bottom: 30px;
    margin-right: 20px;
    font-size: 20px;
}

input[type="text"] {
    outline: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #2c3e50 inset;
    -webkit-text-fill-color: #fff !important;
}

button {
    border: 0;
    padding: 8px 20px;
    margin: 0 2px;
    font-weight: lighter;
    letter-spacing: 1px;
    font-size: 15px;
    cursor: pointer;
    background-color:#0a2333;
    border:2px solid #4286f4;
    color: #fff;
    font-weight: 100;
    border-radius:10px;
}

button:active {
    outline: none;
}
button:hover{
    background-color:#4e75b2;
}

.weather__info {
    width: 60%;
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 2px;
}

.weather__key {
    color: #e09214;
    padding: 20px 0 20px 0;
    font-weight: 400;
}

.weather__key:last-child {
    border: 0;
    padding: 0px;
}
.weather_temp{
    border:2px solid #4286f4;
    border-radius:20px;
    width:150px;
    text-align: center;
    padding-right: 10px;
}

.weather__value {
    color: #fff;
    font-weight: 200;
}

.weather__error {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 200;
}
.celsiusSign{
    font-size:12px;
    margin-top:0px;
    margin-left:2px;
    position: absolute;
}
.weatherBanner{
    float:right;
    margin-right:260px;
    margin-top:-300px;
    height:295px;
    width:500px;
}
.hidden{
    display: none;
}
@media all and (max-width:1200px)
{
    .weatherBanner{
        display: none;
    }
}
@media all and (max-width:900px)
{
    .form-container{
        margin-top:240px;
    }
}